import Dashboard from './Dashboard'
import Stores from './Store/AddStores'
import AddStores from './Store/AddStores/addStore'
import ApproveProduct from './Product/Approve'
import PendingProduct from './Product/Pending'
import ReworkProduct from './Product/Rework'
import BulkEditProduct from './Product/BulkEdit'
import BulkFilterProduct from './Product/BulkFilter'
import AddProduct from './Product/addProduct'
import Brand from './Data/Brand'
import ProductType from './Data/ProductType'
import Material from './Data/Material'
import Color from './Data/Color'
import Shape from './Data/Shape'
import BridgeSizeList from './Data/BridgeSize/List'
import AddBridgeSize from './Data/BridgeSize/AddBridgeSize'
import TempleSizeList from './Data/TempleSize/List'
import AddTempleSize from './Data/TempleSize/AddTempleSize'
import SizeList from './Data/Size/List'
import AddSize from './Data/Size/AddSize'

import AddBrands from './Data/Brand/AddBrand'
import Supplier from './Data/Supplier'
import AddSupplier from './Data/Supplier/addSupplier'
import SubAdmin from './Setting/SubAdmin'
import AddSubAdmin from './Setting/SubAdmin/addSubAdmin'
import Permissions from './Setting/Permissions'
// import Inventory from './Inventory/Addition'
import PendingInventory from './Inventory/PendingInventory'
import ApproveInventory from './Inventory/ApproveInventory'
import InventorySearch from './Inventory/SearchIn'
import InventoryQuantity from './Inventory/Quantity'
import AddInventory from './Inventory/Addition/AddInventory'
import BarcodeStatus from './Inventory/BarcodeStatus'
import PendingBarcodeStatus from './Inventory/BarcodeStatus/pendingIndex'
import MoveOutBarcode from "./Inventory/MoveOutBarcode"
import LostBarcodeHistory from "./Inventory/LostBarcodeHistory"
import LostBarcode from "./Inventory/LostBarcode"

import Role from './Setting/Role'
import AddRole from './Setting/Role/addRole'
import BrandAllocation from './Store/BrandAllocation'
import StoreAllocation from './Store/AllocatedBrands/index'
import EditBrandAllocation from './Store/AllocatedBrands/EditBrandAllocation'
import AddBrandAllocation from './Store/BrandAllocation/AddBrandAllocation'
import Barcode from '../../components/molecules/Barcode'
import Movement from './Movement/Movement'
import MovementDetail from './Movement/MovementDetails'
import MovementPending from './Movement/Pending'
import MovementInprocess from './Movement/In-process'
import MovementDelivered from './Movement/Delivered'
import MovementCancelled from './Movement/Cancelled'
import BulkUpload from './Product/BulkUpload'
import SubAccount from './Accounts/Sub-Account'
import GeneralAccount from './Accounts/General-Account'
import AddVoucher from './Accounts/AddVoucher'
import AllEntries from './Accounts/AllEntries'
import Procurement from './Store/Procurement'
import LoyaltyPoints from './Report/LoyaltyPoints/LoyaltyPoints'
import LoyaltyPointsItems from './Report/LoyaltyPoints/LoyaltyPointsItems'
import Warranty from './Warranty'
import AddLoyalty from './Setting/Other/AddLoyalty'
import LoyaltyList from './Setting/Other/List'
import InventoryAudit from './Inventory/InventoryAudit'

import PurchasedReport from './Report/PurchaseReport'
import StoreSalesReport from './Report/StoreSalesReport'
import InventoryMovementReport from './Report/InventoryMovementReport'
import InventoryReport from './Report/InventoryReport'
import GSTOutputReport from './Report/GSTOutputReport'
import CustomerReport from './Report/CustomerReport'
import StoreReport from './Report/StoreReport'
import BarcodeAuditTrail from './Report/BarcodeAuditTrail'
import InventoryAdditionReport from './Report/InventoryAdditionReport'
import InvoicingReport from './Report/InvoicingReport'
import OwnershipTransferReport from './Report/OwnershipTransferReport'
import TransactionReport from './Accounts/TransactionReport'
import GeneralAccountReport from './Accounts/GeneralAccountReport'
import StoreBalanceReport from './Accounts/StoreAccountReport'
import TrailBalanceReport from './Accounts/TrailBalanceReport'
import StoreSalesReturn from './Report/StoreSalesReturn'
import InvoicingReturn from './Report/InvoicingReturn'
import FeedbackForms from './WebsiteForms/FeedbackForm'
import RetailerForms from './WebsiteForms/RetailerForm'
import LocationGroup from './Setting/LocationGroup'
import LocationMatrix from './Setting/LocationMatrix'
import BulkApprove from "./Product/BulkApprove"

const pages = {
  Dashboard,
  Stores,
  AddStores,
  ApproveProduct,
  PendingProduct,
  ReworkProduct,
  BulkEditProduct,
  BulkFilterProduct,
  AddProduct,
  Brand,
  ProductType,
  Material,
  Color,
  Shape,
  AddBrands,
  BridgeSizeList,
  AddBridgeSize,
  TempleSizeList,
  AddTempleSize,
  SizeList,
  AddSize,
  Supplier,
  AddSupplier,
  SubAdmin,
  AddSubAdmin,
  Permissions,
  PendingInventory,
  ApproveInventory,
  AddInventory,
  BarcodeStatus,
  PendingBarcodeStatus,
  MoveOutBarcode,
  LostBarcodeHistory,
  LostBarcode,
  Role,
  AddRole,
  StoreAllocation,
  BrandAllocation,
  EditBrandAllocation,
  AddBrandAllocation,
  InventorySearch,
  InventoryQuantity,
  Barcode,
  Movement,
  MovementDetail,
  MovementPending,
  MovementInprocess,
  MovementCancelled,
  BulkUpload,
  MovementDelivered,
  SubAccount,
  GeneralAccount,
  AllEntries,
  AddVoucher,
  Procurement,
  LoyaltyPoints,
  LoyaltyPointsItems,
  AddLoyalty,
  Warranty,
  LoyaltyList,
  InventoryAudit,

  PurchasedReport,
  StoreSalesReport,
  InventoryMovementReport,
  InventoryReport,
  GSTOutputReport,
  CustomerReport,
  StoreReport,
  BarcodeAuditTrail,
  InventoryAdditionReport,
  InvoicingReport,
  TransactionReport,
  GeneralAccountReport,
  OwnershipTransferReport,
  StoreBalanceReport,
  TrailBalanceReport,
  StoreSalesReturn,
  InvoicingReturn,
  FeedbackForms,
  RetailerForms,
  LocationGroup,
  LocationMatrix,
  BulkApprove,
 
}
export default pages
